import { Link as ReactRouterLink } from 'react-router-dom';

import {
    Box,
    Flex,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    Text,
    Link,
    Image,
    Icon,
    Button,
} from '@chakra-ui/react';

import { MdEmail } from "react-icons/md";

export default function LetsCollaborate() {
    return (
        <Box
            w='100%'
            px='1em'
            py='1.7em'
            backgroundColor='mediumspringgreen'
        >
            <Text fontSize='3xl' fontWeight='bold' align='center'>
                Let's Collaborate 🤝
            </Text>

            <Text mt='0.5em' fontSize='xl' align='center'>
                Ready to transform your ideas or needs into a stunning, functional web app?
            </Text>

            <Wrap
                mt='1em'
                justify='center'
            >
                <WrapItem>
                    <a href='mailto:royastik27@gmail.com'>
                    <Button
                        colorScheme='pink'
                        fontSize='lg'
                        size='md'
                    >
                        Discuss&nbsp;<Icon as={MdEmail} />
                    </Button>
                    </a>
                </WrapItem>

                <WrapItem>
                    <Link to='/collab' as={ReactRouterLink}>
                    <Button
                        colorScheme='orange'
                        fontSize='lg'
                        size='md'
                    >
                        Propose a Project 🚀
                    </Button>
                    </Link>
                </WrapItem>

            </Wrap>

        </Box>
    );
}