import { useState, useRef } from 'react';

import {
    Box,
    VStack,
    HStack,
    Card,
    CardBody,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    useToast,
} from '@chakra-ui/react';

function PasswordInput({ ref, placeholder }) {

    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    return (
      <InputGroup>
        <Input
            ref={ref}
            type={show ? 'text' : 'password'}
            placeholder={placeholder}
            variant='flushed'
        />
        <InputRightElement>
          <Button onClick={handleClick} variant='ghost'>
            {show ? '🙈' : '🧐'}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
}

export default function Settings() {

    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();
    const retypeNewPasswordRef = useRef();

    const toast = useToast();

    async function handleChangePassword() {
        try {
            const currentPassword = currentPasswordRef.current.value;
            const newPassword = newPasswordRef.current.value;
            const retypeNewPassword = retypeNewPasswordRef.current.value;

            if(!currentPassword || !newPassword || !retypeNewPassword)
                throw new Error('Missing input(s)');

            if(newPassword !== retypeNewPassword)
                throw new Error('Passwords do not match');

            const response = await fetch('/api/auth/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currentPassword, newPassword }),
            });

            const res = await response.json();
    
            if(res.status === 'failed')
                throw new Error(res.message);

            toast({
                status: 'success',
                description: 'Password changed successfully!'
            });
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }
    return (
        <Box pt='0.5em'>
            <Card
                mx='auto'
                width={{base: '98%', md: '60%'}}
            ><CardBody>

                <Text mb='0.5em'>Change Password 🔑</Text>
                <hr />

                <VStack mt='0.7em' align='left'>
                    <PasswordInput ref={currentPasswordRef} placeholder='Current password' />
                    <PasswordInput ref={newPasswordRef} placeholder='New password' />
                    <PasswordInput ref={retypeNewPasswordRef} placeholder='Retype new password' />

                    <HStack justify='center'>
                        <Button onClick={handleChangePassword} mt='1em' colorScheme='teal'>
                            Save
                        </Button>
                    </HStack>
                </VStack>


            </CardBody></Card>
        </Box>
    );
}