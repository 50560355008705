import {
    Box,
    Flex,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    Text,
    Image,
} from '@chakra-ui/react';

export default function MyToolbox() {
    return (
        <Box
            w='100%'
            px='1em'
            py='1.7em'
            // backgroundColor='lightyellow'
            backgroundColor='lavender'
        >
            <Text fontSize='3xl' fontWeight='bold' align='center'>
                My Toolbox 🧰
            </Text>

            <Wrap
                spacing='1.5em'
                spacingY='2em'
                mt='1.7em'
                w={{ base: '100%', md: '60%' }}
                mx='auto'
                border='0px solid black'
                justify='center'
            >
                {ToolBoxSVGs.map((el, idx) =>
                <WrapItem key={idx}>
                    <Image src={`https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/${el}`} width='4em' draggable='false' />
                </WrapItem>
                )}
            </Wrap>        

        </Box>
    );
}

const ToolBoxSVGs = [
    'c/c-original.svg',
    'cplusplus/cplusplus-original.svg',
    'html5/html5-original.svg',
    'css3/css3-original.svg',
    'bootstrap/bootstrap-original.svg',
    'tailwindcss/tailwindcss-original.svg',
    'javascript/javascript-original.svg',
    'json/json-original.svg',
    'markdown/markdown-original.svg',
    'react/react-original.svg',
    'reactrouter/reactrouter-original.svg',
    'nodejs/nodejs-original-wordmark.svg',
    'express/express-original.svg',
    'mongodb/mongodb-original-wordmark.svg',
    'npm/npm-original-wordmark.svg',
    'git/git-original.svg',
    'postman/postman-original.svg',
    'linux/linux-original.svg',
];