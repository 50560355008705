import { useState, useEffect } from 'react';
import {
  useToast,
} from '@chakra-ui/react';

import './App.css';

import Navbar from './components/primary/Navbar/Navbar.js';
import MainContent from './components/primary/MainContent/MainContent.js';
import Footer from './components/primary/Footer/Footer.js';

import UserContext from './contexts/UserContext.js';

function App() {

  const [userState, setUserState] = useState({
    isLoggedIn: false,
    userId: null,
    role: 'guest', // Possible values: 'guest', 'user', 'admin'
  });

  const toast = useToast();

  async function checkAuth() {
    try {

      const response = await fetch('/api/auth/check-auth', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      const res = await response.json();

      if(res.status === 'failed')
          throw new Error(res.message);

      const { user } = res.data;
        
      // Changing app state
      setUserState({
          isLoggedIn: true,
          userId: user._id,
          role: user.role
      });
      
      // toast({
      //     status: 'success',
      //     description: `Welcome ${user.role}!`
      // });
    }
    catch(err) {
      // toast({
      //     status: 'info',
      //     description: 'Create an account to enjoy full experience.',
      // });
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <div className="App">
      <UserContext.Provider value={{ userState, setUserState }}>
        
        <Navbar />
        <MainContent />
        <Footer />

      </UserContext.Provider>      
    </div>
  );
}

export default App;
