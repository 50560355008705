import { useState, useRef, useContext } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import {
    Box,
    VStack,
    HStack,
    Text,
    Image,
    Button,
    Link,
    InputGroup,
    Input,
    InputRightElement,
    useToast,
} from '@chakra-ui/react';

import UserContext from '../../contexts/UserContext.js';

function PasswordInput({ passwordRef }) {

    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    return (
      <InputGroup>
        <Input
            ref={passwordRef}
            type={show ? 'text' : 'password'}
            placeholder='Password'
            variant='flushed'
        />
        <InputRightElement>
          <Button onClick={handleClick} variant='ghost'>
            {show ? '🙈' : '🧐'}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
}

export default function LogIn() {

    const { setUserState } = useContext(UserContext);

    const usernameRef = useRef();
    const passwordRef = useRef();

    const toast = useToast();
    const navigate = useNavigate();

    async function handleLogIn() {
        try {
            const username = usernameRef.current.value;
            const password = passwordRef.current.value;

            if(!username.trim() || !password.trim())
                throw new Error('Missing input(s)');

            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { user } = res.data;

            setUserState({
                isLoggedIn: true,
                userId: user._id,
                role: user.role,
            });

            toast({
                status: 'success',
                description: 'Logged in successfully!'
            });

            navigate('/profile');
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    return (
        <Box pt='1em'>
            <VStack
                backgroundColor='white'
                borderRadius='0.5em'
                mx='auto'
                p='1.5em'
                w={{base: '100%', md: '40%' }}
                align='left'
            >
                <Text fontSize='lg' align='center' className='no-select'>Log In 🔒</Text>
                <hr />

                <VStack mt='1em' spacing='0.7em' align='left'>
                    <Input ref={usernameRef} type='text' placeholder='Username' variant='flushed' />
                    <PasswordInput passwordRef={passwordRef} />

                    <HStack justify='center'>
                        <Button onClick={handleLogIn} mt='1em' colorScheme='orange'>
                            Log in 🔑
                        </Button>
                    </HStack>
                </VStack>

                <Link mt='1em' as={ReactRouterLink} to='/signup'>
                    <Text align='center'>
                        Don't have an account? Create one.
                    </Text>
                </Link>

            </VStack>
        </Box>
    );
}