import { useState, useContext } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
    Box,
    HStack,
    VStack,
    Card,
    CardBody,
    Text,
    Button,
    IconButton,
    Icon,
    Link,
    useToast,
} from '@chakra-ui/react';

import { MdBookmark, MdBookmarkBorder } from "react-icons/md";

import UserContext from './../../../contexts/UserContext.js';

function BookmarkButton({ blogId, alreadyBookmarked }) {

    const [isBookmarked, setIsBookmarked] = useState(alreadyBookmarked);

    const toast = useToast();
  
    async function handleToggleBookmark() {
        try {
            const response = await fetch(`/api/blogs/bookmarks/${isBookmarked ? blogId : ''}`, {
                method: isBookmarked ? 'DELETE' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                ...(isBookmarked ? {} : { body: JSON.stringify({ blogId }) }),
            });

            const res = await response.json();
    
            if(res.status === 'failed')
                throw new Error(res.message);

            toast({
                status: 'success',
                description: isBookmarked ? 'Bookmark removed' : 'Bookmark added!'
            });

            setIsBookmarked((prev) => !prev);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    };

    return (
        <Icon
            onClick={handleToggleBookmark}
            as={isBookmarked ? MdBookmark : MdBookmarkBorder}
            fontSize='2xl'
            cursor='pointer'
        />
    );
}

export default function BlogCard({ blog, alreadyBookmarked }) {

    const { userState } = useContext(UserContext);

    return (
        <Card>
            <CardBody>
                <Text fontSize='lg' fontWeight='bold'>
                    <Link as={ReactRouterLink} to={`/blogs/${blog._id}`}>
                        {blog.title}
                    </Link>
                </Text>
                <Text color='pink.500'>royastik27 ・ {new Date(blog.createdAt).toLocaleString('en-US', { month: 'short', year: '2-digit' })}</Text>

                <Text mt='0.5em' fontSize='sm'>
                    {blog.tags.map(tag => `#${tag} `)}
                </Text>
                {/* <Text mt='0.5em' fontSize='sm'>#computerscience #academic #multimedia #engineering</Text> */}

                <HStack mt='0.5em' justify='space-between'>
                    <Text color='gray.700'>
                        ❤ {blog.loves.length}
                    </Text>

                    {userState.isLoggedIn &&
                        <BookmarkButton blogId={blog._id} alreadyBookmarked={alreadyBookmarked} />
                    }
                </HStack>
                
            </CardBody>
        </Card>
    );
}