import { useState, useEffect } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
    Box,
    VStack,
    HStack,
    Text,
    Link,
    Button,
    Icon,
    Card,
    CardBody,
    useToast,
} from '@chakra-ui/react';

import BlogCard from './../../../components/secondary/BlogCard/BlogCard.js';

export default function Bookmarks() {

    const [bookmarks, setBookmarks] = useState([]);

    const toast = useToast();

    async function fetchAllBookmarks() {
        try {
            const response = await fetch('/api/blogs/bookmarks?populate=true', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { bookmarks } = res.data;

            setBookmarks(bookmarks);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    useEffect(() => {
        fetchAllBookmarks();
    }, []);

    return (
        <Box>
            <Text mb='0.5em' className='no-select'>
                📝 <Link as={ReactRouterLink} to='/blogs'>
                    Blogs
                </Link> / Bookmarks
            </Text>
            <hr />

            <VStack mt='0.7em' spacing='0.7em' align='left'>
                {!bookmarks.length && 
                    <Text fontStyle='italic'>No bookmarks yet. Bookmark blogs for quick access or to read later!</Text>
                }
                {bookmarks.map((blog, idx) =>
                    <BlogCard blog={blog} alreadyBookmarked={true} key={idx} />
                )}
            </VStack>
        </Box>
    );
}