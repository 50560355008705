import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Image,
} from '@chakra-ui/react';

import Banner from './Banner.js';
import WhoAmI from './WhoAmI.js';
import WhatIDo from './WhatIDo.js';
import WhyMe from './WhyMe.js';
import MyToolbox from './MyToolbox.js';
import LetsCollaborate from './LetsCollaborate.js';
import ConnectWithMe from './ConnectWithMe.js';

export default function Home() {
    return (
        <Box
            className='no-select'
        >
            <Banner />
            <WhoAmI />
            <WhatIDo />
            <WhyMe />
            <MyToolbox />
            <LetsCollaborate />
            <ConnectWithMe />
        </Box>
    );
}