import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Image,
} from '@chakra-ui/react';

import WhoAmIImg from './whoami.svg';

export default function WhoAmI() {
    return (
        <Box
            w='100%'
            px='1em'
            py='1.7em'
            backgroundColor='beige'
        >
            <Text fontSize='3xl' fontWeight='bold' align='center'>
                Who am I?
            </Text>

            <Flex
                mt='1.7em'
                direction={{ base: 'column', md: 'row' }}
                justifyContent='space-evenly'
                border='0px solid black'
            >
                <Image
                    border='0px solid black'
                    width='xs'
                    src={WhoAmIImg}
                    mx={{ base: 'auto', md: 0 }}
                    draggable='false'
                />

                <VStack
                    border='0px solid black'
                    mt={{ base: '1em', md: 0 }}
                    width={{ base: '100%', md: '40%' }}
                    justify='center'
                    align='left'
                >
                    <Text fontSize='3xl' align='center'>
                        Sri Astik Roy
                    </Text>
                    <Text fontSize='xl' align='left'>
                        Professional Web Application Developer and Aspiring Software Engineer
                    </Text>
                    <Text fontSize='xl' align='left'>
                        B.Sc. (Engineering) in Computer Science and Engineering
                    </Text>
                </VStack>
            </Flex>
        </Box>
    );
}