import { Link as ReactRouterLink } from 'react-router-dom';
import {
    Box,
    Flex,
    HStack,
    Text,
    Link,
} from '@chakra-ui/react';

export default function Footer() {
    return (
        <Box
            w='100%'
            p='0.7rem'
            borderTop='1px solid #ededed'
            // backgroundColor='#FFFDF2'
            className='no-select'
        >
            <Text
                textAlign='center'
            >
                Built with 💖 by royastik27
            </Text>
        </Box>
    );
}