import {
    Box,
    Flex,
    VStack,
    HStack,
    Text,
    Image,
} from '@chakra-ui/react';

import BannerImg from './banner.svg';

export default function Banner() {
    return (
        <Flex
            w='100%'
            p='1em'
            direction={{ base: 'column', md: 'row' }}
            justifyContent='space-evenly'
            backgroundColor='white'
            border='0px solid black'
        >
            <VStack border='0px solid black' justify='center'>
                <Text fontSize='3xl' align='center'>Deep dive into the ocean of royastik27</Text>
                <Text fontSize='xl' align='center'>and explore the wonders ✨</Text>
            </VStack>
            <Image
                border='0px solid black'
                width='lg'
                src={BannerImg}
                mx={{ base: 'auto', md: 0 }}
                draggable='false'
            />
        </Flex>
    );
}