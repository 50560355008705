import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Text,
    useToast
} from '@chakra-ui/react';

import UserContext from './../../contexts/UserContext.js';

export default function LogOut() {

    const { setUserState } = useContext(UserContext);

    const toast = useToast();
    const navigate = useNavigate();

    async function handleLogOut() {      
        try {
            const response = await fetch('/api/auth/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            // Changing app state
            setUserState({
                isLoggedIn: false,
                userId: null,
                role: 'guest'
            });
            
            // toast
            toast({
                status: 'success',
                description: 'Logged out successfully!'
            });

            navigate('/login');
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    useEffect(() => {
        handleLogOut();
    }, []);

    return (
        <Box pt='2em'>
            
            <Text
                align='center'
                className='no-select'
            >Logging out...</Text>

        </Box>
    );
}