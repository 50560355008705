import { useState, useEffect, useContext } from 'react';

import {
    Box,
    VStack,
    HStack,
    Card,
    CardBody,
    Text,
    useToast,
} from '@chakra-ui/react';

import UserContext from './../../contexts/UserContext.js';

export default function Profile() {

    const { userState } = useContext(UserContext);
    const [ user, setUser] = useState({});

    const toast = useToast();

    async function fetchUser() {
        try {
            const response = await fetch(`/api/users/${userState.userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { user } = res.data;

            setUser(user);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <Box pt='0.5em'>
            <Card
                mx='auto'
                width={{base: '98%', md: '60%'}}
            ><CardBody>

                <HStack>
                    <Text fontSize='xl'>👤</Text>

                    <Box>
                        <Text fontSize='lg'>{user.username}</Text>
                        <Text color='gray'>{user.email}</Text>
                    </Box>
                </HStack>


            </CardBody></Card>
        </Box>
    );
}