import { Routes, Route } from 'react-router-dom';

import {
    Box,
    VStack,
    HStack,
    Text,
} from '@chakra-ui/react';

import AllBlogs from './AllBlogs/AllBlogs.js';
import SingleBlog from './SingleBlog/SingleBlog.js';
import NewBlog from './NewBlog/NewBlog.js';
import EditBlog from './EditBlog/EditBlog.js';
import Bookmarks from './Bookmarks/Bookmarks.js';
import Drafts from './Drafts/Drafts.js';
import NotFound from './../NotFound/NotFound.js';

export default function Blogs() {
    return (
        <Box
            w={{ base: '98%', md: '70%' }}
            mx='auto'
            py='0.5em'
            // border={'1px solid blue'}
        >
            <Routes>
                <Route index element={ <AllBlogs/> } />                
                <Route path="new" element={ <NewBlog/> } />
                <Route path="bookmarks" element={ <Bookmarks/> } />
                <Route path="drafts" element={ <Drafts/> } />
                <Route path=":blogId" element={ <SingleBlog/> } />
                <Route path=":blogId/edit" element={ <EditBlog/> } />
                <Route path="/*" element={ <NotFound /> } />
            </Routes>
        </Box>
    );
}