import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { background, ChakraProvider, extendTheme } from '@chakra-ui/react';

import './index.css';

import App from './App';

const fontFamily= `"Merriweather", serif`;

const customTheme = {
  styles: {
    global: {
      body: {
        fontFamily,
      },
      h1: { fontSize: '2xl', fontWeight: 'bold' },
      h2: { fontSize: 'xl', fontWeight: 'semibold' },
      h3: { fontSize: 'lg', fontWeight: 'semibold' },
    },
  },
  // fonts: {
  //   body: fontFamily,
  //   heading: fontFamily,
  // },
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
      }
    },
  },
};

const theme = extendTheme(customTheme);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: 'bottom-right',
            isClosable: true,
          }
        }}
      >
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
