import { memo } from 'react';
import {
    MDXEditor,
    headingsPlugin,
    listsPlugin,
    linkPlugin,
    linkDialogPlugin,
    tablePlugin,
    imagePlugin,
    codeBlockPlugin,
    codeMirrorPlugin,
    directivesPlugin,
    AdmonitionDirectiveDescriptor,
    thematicBreakPlugin,
    quotePlugin,
    toolbarPlugin,
    UndoRedo,
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    StrikeThroughSupSubToggles,
    ListsToggle,
    InsertTable,
    InsertImage,
    InsertCodeBlock,
    InsertAdmonition,
    InsertThematicBreak,
    CreateLink,
    CodeToggle,
} from '@mdxeditor/editor'

import '@mdxeditor/editor/style.css';

import './Editor.css';

import {
    Box,
} from '@chakra-ui/react';

function Editor({ editorRef }) {

    return (
        <Box
            border="1px"
            borderColor="gray.200"
            borderRadius='md'
            minH='60vh'
        >
            <MDXEditor
                ref={editorRef}
                markdown={'Start writing here ✍'}
                contentEditableClassName="editor"
                plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    tablePlugin(),
                    imagePlugin(),
                    codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
                    codeMirrorPlugin({
                        codeBlockLanguages: {
                            c: 'C', cpp: 'C++', csharp: 'C#', js: 'JavaScript', php: 'PHP', py: 'Python',
                        }
                    }),
                    directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
                    thematicBreakPlugin(),
                    quotePlugin(),
                    toolbarPlugin({
                        toolbarClassName: 'my-classname',
                        toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <BlockTypeSelect />
                            <BoldItalicUnderlineToggles />
                            <StrikeThroughSupSubToggles />
                            <ListsToggle />
                            <CreateLink />
                            <CodeToggle />
                            <InsertCodeBlock />
                            <InsertTable />
                            <InsertImage />
                            <InsertAdmonition />
                            <InsertThematicBreak />
                          </>
                        )
                    })
                ]}
            />
        </Box>
    );
}

export default memo(Editor);