import { useEffect, useRef } from 'react';

import {
    Button,
} from '@chakra-ui/react';

import { ArrowUpIcon } from '@chakra-ui/icons';

export default function BackToTop() {

    const backToTopRef = useRef();

    function handleBackToTop() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        window.onscroll = () => {
            if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
                backToTopRef.current.style.display = "block";
            else
                backToTopRef.current.style.display = "none";
        }
    }, []);

    return (
        <Button
            ref={backToTopRef}
            position='fixed'
            bottom='20px'
            right='20px'
            z-index='1'
            display='none'
            colorScheme='orange'
            opacity='70%'
            _hover={{ opacity: '100%' }}
            onClick={handleBackToTop}
        >
            Back to top <ArrowUpIcon ml='0.1rem' />
        </Button>
    );
}