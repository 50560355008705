import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import { useState, useRef, useContext } from 'react';

import {
    Box,
    VStack,
    HStack,
    Text,
    Image,
    Button,
    Link,
    InputGroup,
    Input,
    InputRightElement,
    useToast,
} from '@chakra-ui/react';

import UserContext from '../../contexts/UserContext.js';

function PasswordInput({ ref, placeholder }) {

    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
  
    return (
      <InputGroup>
        <Input
            ref={ref}
            type={show ? 'text' : 'password'}
            placeholder={placeholder}
            variant='flushed'
        />
        <InputRightElement>
          <Button onClick={handleClick} variant='ghost'>
            {show ? '🙈' : '🧐'}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
}

export default function SignUp() {

    const { setUserState } = useContext(UserContext);

    const usernameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const retypePasswordRef = useRef();

    const toast = useToast();
    const navigate = useNavigate();

    async function handleSignUp() {
        try {
            const username = usernameRef.current.value;
            const email = emailRef.current.value;
            const password = passwordRef.current.value;
            const retypePassword = retypePasswordRef.current.value;

            if(!username.trim() || !email.trim() || !password.trim() || !retypePassword.trim())
                throw new Error('Missing input(s)');

            if(password !== retypePassword)
                throw new Error('Passwords do not match');

            const response = await fetch('/api/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: {
                        username,
                        email,
                        password,
                    }
                }),
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            toast({
                status: 'success',
                description: 'Account created successfully!'
            });

            navigate('/login');
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    return (
        <Box pt='1em'>
            <VStack
                backgroundColor='white'
                borderRadius='0.5em'
                mx='auto'
                p='1.5em'
                w={{base: '100%', md: '40%' }}
                align='left'
            >
                <Text fontSize='lg' align='center' className='no-select'>Sign Up ✨</Text>
                <hr />

                <VStack mt='1em' spacing='0.7em' align='left'>
                    <Input ref={usernameRef} type='text' placeholder='Username' variant='flushed' />

                    <Input ref={emailRef} type='email' placeholder='Email' variant='flushed' />

                    <PasswordInput ref={passwordRef} placeholder='Password' />

                    <PasswordInput ref={retypePasswordRef} placeholder='Retype password' />

                    <HStack justify='center'>
                        <Button onClick={handleSignUp} mt='1em' colorScheme='orange'>
                            Create Account 🚀
                        </Button>
                    </HStack>
                </VStack>

                <Link mt='1em' as={ReactRouterLink} to='/login'>
                    <Text align='center'>
                        Already have an account? Log in.
                    </Text>
                </Link>

            </VStack>
        </Box>
    );
}