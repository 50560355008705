import { useState, useEffect, useContext } from 'react';
import { Link as ReactRouterLink, useParams, useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {
    Box,
    VStack,
    HStack,
    Card,
    CardBody,
    Wrap,
    WrapItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Text,
    Link,
    Icon,
    Button,
    useToast,
    useDisclosure,
} from '@chakra-ui/react';

import { MdBookmark, MdBookmarkBorder } from "react-icons/md";

import UserContext from './../../../contexts/UserContext.js';

function LoveButton({ blogId, loves }) {

    const [isLoved, setIsLoved] = useState(false);
    const [nLoves, setNLoves] = useState(0);
    const { userState } = useContext(UserContext);

    const toast = useToast();

    async function handleToggleLove() {
        try {
            const response = await fetch(`/api/blogs/${blogId}/toggle-love`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ isLoved }),
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            setIsLoved(prev => !prev);

            if(isLoved)
                setNLoves(prev => prev - 1); // going to happen
            else setNLoves(prev => prev + 1);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    useEffect(() => {
        setIsLoved(loves?.includes(userState.userId));
        setNLoves(loves?.length);
    }, [loves]);

    return(
        <Button onClick={handleToggleLove} colorScheme={isLoved ? 'gray' : 'pink'} size='sm'>
            Love{isLoved && 'd'} ({nLoves}) ❤
        </Button>
    );
}

function BookmarkButton() {

    const [isBookmarked, setIsBookmarked] = useState(false);

    function handleToggleBookmark() {
        setIsBookmarked(prev => !prev);
    }

    return(
        <Button onClick={handleToggleBookmark} size='sm'>
            Bookmark{isBookmarked && 'ed'}&nbsp;<Icon as={isBookmarked ? MdBookmark : MdBookmarkBorder} />
        </Button>
    );
}

function DeleteButton() {

    const { blogId } = useParams();

    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    async function handleDeleteBlog() {
        try {
            const response = await fetch(`/api/blogs/${blogId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            toast({
                status: 'success',
                description: 'Blog deleted successfully!'
            });

            navigate('/blogs');
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    return (
        <>
        <Button onClick={onOpen} size='sm'>Delete 🗑️</Button>

        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className='no-select'>Delete</ModalHeader>
                <ModalCloseButton />
                
                <ModalBody pb='1.2em'>

                    <Text>Are you sure to delete this blog?</Text>

                    <HStack mt='1.5em' justify='flex-end'>
                        <Button onClick={onClose} size='sm'>Cancel</Button>
                        <Button onClick={handleDeleteBlog} size='sm' colorScheme='red'>Yes, delete 🗑️</Button>
                    </HStack>

                </ModalBody>

            </ModalContent>
        </Modal>
        </>
    );
}

export default function SingleBlog() {

    const { userState } = useContext(UserContext);
    const { blogId } = useParams();
    const [blog, setBlog] = useState({});

    const toast = useToast();

    async function fetchBlog() {
        try {
            const response = await fetch(`/api/blogs/${blogId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { blog } = res.data;

            setBlog(blog);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    useEffect(() => {
        fetchBlog();
    }, [blogId]);

    return (
        <Box pt='0.5em'>
            <Card><CardBody>
                
                <Text fontSize='2xl' fontWeight='bold'>{blog.title}</Text>

                <Text color='pink.500'>royastik27 ・ {new Date(blog.createdAt).toLocaleString('en-US', { month: 'short', year: '2-digit' })}</Text>
                
                <Text mt='0.5em'>{blog.tags?.map(tag => `#${tag} `)}</Text>

                <Text mt='0.5em' fontSize='sm'>Last updated: {new Date(blog.updatedAt).toLocaleDateString()}</Text>

                {userState.isLoggedIn &&
                    <Wrap mt='0.7em'>
                        <WrapItem>
                            <LoveButton blogId={blogId} loves={blog.loves} />
                        </WrapItem>

                        <WrapItem>
                            <BookmarkButton />
                        </WrapItem>

                        {userState.role === 'admin' &&
                            <>
                            <WrapItem>
                            <Link as={ReactRouterLink} to='edit'>
                                <Button size='sm'>Edit ✍</Button>
                            </Link>
                            </WrapItem>

                            <WrapItem>
                                <DeleteButton />
                            </WrapItem>
                            </>
                        }
                        
                    </Wrap>
                }

                <Box mt='1.5em'>
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            a: ({ href, children }) => <Link as={ReactRouterLink} to={href}>{children}</Link>,
                        }}
                    >
                        {blog.content}
                    </Markdown>
                </Box>

            </CardBody></Card>
        </Box>
    );
}