import {
    Box,
    Flex,
    VStack,
    HStack,
    SimpleGrid,
    Wrap,
    WrapItem,
    Text,
    Image,
} from '@chakra-ui/react';

import WhyMeImg from './whyme.svg';

export default function WhyMe() {
    return (
        <Box
            w='100%'
            px='1em'
            py='1.7em'
            backgroundColor='lightpink'
        >
            <Text fontSize='3xl' fontWeight='bold' align='center'>
                Why Me?
            </Text>

                <Image
                    mt='1.7em'
                    width='xs'
                    src={WhyMeImg}
                    mx='auto'
                    draggable='false'
                    border='0px solid black'
                />

                <Flex
                    mt='1em'
                    px={{ base: 0, md: '2em' }}
                    direction={{ base: 'column', md: 'row' }}
                    justify='center' // x axis
                    gap='1.5em'
                    textAlign='justify'
                    border='0px solid black'
                >
                    <VStack flex='1' spacing='1.5em'>
                        {WhyMeData.slice(0, 3).map((el, idx) =>
                                <Text fontSize='lg' key={idx}>
                                    <Text as='span' fontSize='xl' fontWeight='bold'>{el.title}:</Text> {el.description}
                                </Text>
                        )}
                    </VStack>

                    <VStack flex='1' spacing='1.5em'>
                        {WhyMeData.slice(3).map((el, idx) =>
                                <Text fontSize='lg' key={idx}>
                                    <Text as='span' fontSize='xl' fontWeight='bold'>{el.title}:</Text> {el.description}
                                </Text>
                        )}
                    </VStack>
                </Flex>

        </Box>
    );
}

const WhyMeData = [
    {
        title: '💻 Expertise',
        description: 'With deep knowledge in modern, advanced, and third-party technologies, I can bring any web app idea to life — exactly as you imagine. As a final-year Computer Science & Engineering student, I understand how technologies work behind the scenes, making me capable of handling any complex project.'
    },
    {
        title: '🧠 Problem Solving',
        description: 'A experienced competitive programmer with a long track record in problem-solving. I’m a Specialist on CodeForces and a Blue Coder on CodeChef — ready to tackle any challenge with efficient solutions.'
    },
    {
        title: '🚀 Scalability',
        description: 'I follow the best coding standards, ensuring that any future developer can easily continue from where I left off. Your app will be built to grow — adding new features will never be a hassle.'
    },
    {
        title: '🛡️ Security',
        description: 'Trained in cybersecurity by an OWASP chair (my senior), I ensure top-notch security in every app I build. You won’t need to hire a separate security expert — I code with security best practices in mind.'
    },
    {
        title: '✅ Reliability',
        description: 'You’ll get to see the live progress of your project anytime or enjoy demos — keeping you updated and assured throughout development.'
    },
    {
        title: '💲 Reasonable Pricing',
        description: 'I offer affordable pricing, sometimes even the lowest, because building apps is not just my job — it’s my passion.'
    },
    {
        title: '💬 Smooth Communication',
        description: 'With strong English skills, I ensure clear communication and make you understand every tiny detail of your project — making the whole journey easy and stress-free.'
    }
];