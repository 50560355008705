import {
    Box,
    Flex,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    Text,
    Image,
    Icon,
    Button,
} from '@chakra-ui/react';

import { FaFacebookSquare, FaFacebookMessenger, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function ConnectWithMe() {
    return (
        <Box
            w='100%'
            px='1em'
            py='1.7em'
            backgroundColor='sandybrown'
        >
            <Text fontSize='3xl' fontWeight='bold' align='center'>
                Connect With Me 🔗
            </Text>

            {/* <Text mt='0.5em' fontSize='xl' align='center'>
                Ready to transform your ideas or needs into a stunning, functional web app?
            </Text> */}

            <Wrap
                mt='1em'
                justify='center'
            >
                {ConnectWithMeLinks.map((link, idx) =>
                    <WrapItem key={idx}>
                        <a href={link.url} target={link.title === 'Email' ? '_self' : '_blank'}>
                        <Button leftIcon={<Icon as={link.icon} />} fontSize='lg' colorScheme='green'>
                            {link.title}
                        </Button>
                        </a>
                    </WrapItem>
                )}

            </Wrap>

        </Box>
    );
}

const ConnectWithMeLinks = [
    {
        title: 'Email',
        url: 'mailto:royastik27@gmail.com',
        icon: MdEmail
    },
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/royastik27',
        icon: FaFacebookSquare
    },
    {
        title: 'Messenger',
        url: 'https://m.me/royastik27',
        icon: FaFacebookMessenger
    },
    {
        title: 'GitHub',
        url: 'https://www.github.com/royastik27',
        icon: FaGithub
    },
];