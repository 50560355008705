import { useNavigate } from 'react-router-dom';

import {
    Box,
    VStack,
    HStack,
    Card,
    CardBody,
    UnorderedList,
    ListItem,
    Text,
    Button,
} from '@chakra-ui/react';

export default function NotFound() {

    const navigate = useNavigate();

    return (
        <Box pt='0.5em'>
            <Card
                mx='auto'
                width={{base: '98%', md: '60%'}}
            ><CardBody>

                <Text fontSize='lg' align='center' className='no-select'>
                    I didn't find what you're looking for 😐
                </Text>

                <Text mt='1em'>Possible cases:</Text>
                <UnorderedList>
                    <ListItem>
                        The URL you enterred is incorrect.
                    </ListItem>
                    <ListItem>
                        The author might be updating the content or it has been temporarily removed.
                    </ListItem>
                    <ListItem>
                        You are not authorized or don't have permission to view the content.
                    </ListItem>
                </UnorderedList>

                <HStack mt='1em' justify='center'>
                    <Button onClick={() => navigate(-1)}>
                        Go back
                    </Button>
                </HStack>

            </CardBody></Card>
        </Box>
    );
}