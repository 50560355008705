import { useState, useEffect, useContext, useRef } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
    Box,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    Text,
    Link,
    Button,
    Icon,
    Card,
    CardBody,
    useToast,
} from '@chakra-ui/react';

import BlogCard from './../../../components/secondary/BlogCard/BlogCard.js';

import UserContext from './../../../contexts/UserContext.js';

export default function AllBlogs() {

    const { userState } = useContext(UserContext);
    const [blogs, setBlogs] = useState([]);
    const [bookmarks, setBookmarks] = useState([]);

    const loadMoreRef = useRef();

    const toast = useToast();

    async function fetchBlogs(lastBlogId) {
        try {
            const response = await fetch(`/api/blogs${lastBlogId ? `?lastBlogId=${lastBlogId}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { blogs } = res.data;

            // setBlogs(blogs);
            setBlogs(prev => [...prev, ...blogs]);

            // Load More Button
            if(!blogs.length) {
                loadMoreRef.current.disabled = true;
                loadMoreRef.current.textContent = 'You are all caught up!';
            }
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    async function fetchAllBookmarks() {
        try {
            const response = await fetch('/api/blogs/bookmarks', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            const { bookmarks } = res.data;

            setBookmarks(bookmarks);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    function handleLoadMore() {

        if(!blogs.length) return;

        const lastBlogId = blogs[blogs.length-1]._id;
        fetchBlogs(lastBlogId);
    }

    useEffect(() => {
        fetchBlogs();

        // if(userState.isLoggedIn)
        //     fetchAllBookmarks();
    }, []);

    useEffect(() => {
        if(userState.isLoggedIn)
            fetchAllBookmarks();
    }, [userState]);

    return (
        <Box>
            <Text mb='0.5em' className='no-select'>📝 Blogs</Text>
            <hr />
            
            <Wrap my='0.7em'>
                <WrapItem>
                <Button size='sm' colorScheme='pink'>Search 🔍</Button>
                </WrapItem>

                {userState.isLoggedIn &&
                    <WrapItem>
                    <Link as={ReactRouterLink} to='bookmarks'>
                        <Button size='sm'>Bookmarks 🔖</Button>
                    </Link>
                    </WrapItem>
                }
                
                {userState.role === 'admin' &&
                    <>
                    <WrapItem>
                    <Link as={ReactRouterLink} to='new'>
                        <Button size='sm' colorScheme='orange'>Write ✍</Button>
                    </Link>
                    </WrapItem>
                    
                    <WrapItem>
                    <Link as={ReactRouterLink} to='drafts'>
                        <Button size='sm'>Drafts 🗄️</Button>
                    </Link>
                    </WrapItem>
                    </>
                }
            </Wrap>

            <VStack spacing='0.7em' align='left'>
                {!blogs.length && 
                    <Text>Fetching blogs...</Text>
                }
                {blogs.map((blog, idx) =>
                    <BlogCard blog={blog} alreadyBookmarked={bookmarks.includes(blog._id)} key={idx} />
                )}
            </VStack>

            <Button
                ref={loadMoreRef}
                display={blogs.length ? 'block' : 'none'}
                mt='1em'
                mx='auto'
                colorScheme='teal'
                onClick={handleLoadMore}
            >
                Load More
            </Button>
        </Box>
    );
}