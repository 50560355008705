import { Routes, Route } from 'react-router-dom';

import {
    Box,
    Button,
} from '@chakra-ui/react';

import BackToTop from './../../secondary/BackToTop/BackToTop.js';

import Home from './../../../routes/Home/Home.js';
import Blogs from './../../../routes/Blogs/Blogs.js';

import LogIn from './../../../routes/LogIn/LogIn.js';
import LogOut from './../../../routes/LogOut/LogOut.js';
import SignUp from './../../../routes/SignUp/SignUp.js';

import Profile from './../../../routes/Profile/Profile.js';
import Settings from './../../../routes/Settings/Settings.js';

import NotFound from './../../../routes/NotFound/NotFound.js';

export default function MainContent() {
    return (
        <Box
            minH={'88vh'}
            w={'100%'}
            backgroundColor='#F6F6F6'
            // pb='1em'
            id="main-content"
        >
            
             <Routes>
                <Route path="/" element={ <Home/> } />                
                <Route path="/blogs/*" element={ <Blogs/> } />                
                <Route path="/login" element={ <LogIn/> } />                
                <Route path="/logout" element={ <LogOut/> } />                
                <Route path="/signup" element={ <SignUp/> } />                

                <Route path="/profile" element={ <Profile/> } />                
                <Route path="/settings" element={ <Settings/> } />
                {/* <Route path="/pricing" element={ <Pricing /> } />                
                <Route path="/brands/:userId" element={ <Brand /> } />
                <Route path="/influencers/:userId" element={ <Influencer /> } />
                <Route path="/signup/*" element={ <SignUp /> } /> */}
                <Route path="/*" element={ <NotFound /> } />
            </Routes>

            <BackToTop />

        </Box>
    );
}