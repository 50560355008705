import { useState, useRef } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {
    Box,
    VStack,
    HStack,
    Card,
    CardBody,
    Text,
    Link,
    Button,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    useToast,
    useDisclosure,
} from '@chakra-ui/react';

import Editor from './../../../components/secondary/Editor/Editor.js';

function Preview({ editorRef }) {

    const [content, setContent] = useState({});

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    function handlePreview() {
        onOpen();

        try {
            const content = editorRef.current?.getMarkdown();

            setContent(content);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    return (
        <>
            <Button
                onClick={handlePreview}
            >
                Preview 📃
            </Button>

            <Modal size='xl' isOpen={isOpen} onClose={onClose} scrollBehavior='inside' isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Preview</ModalHeader>
                    <ModalCloseButton />
                    
                    <ModalBody pb='1.2em'>

                        <Markdown
                            remarkPlugins={[remarkGfm]}
                        >
                            {content}
                        </Markdown>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default function NewBlog() {
    
    const editorRef = useRef(null);

    const titleRef = useRef();
    const tagsRef = useRef();

    const toast = useToast();
    const navigate = useNavigate();

    async function saveBlog(status) {

        try {
            const title = titleRef.current.value.trim();

            // for tags
            const tags = tagsRef.current.value.match(/#(\w+)/g)?.map(tag => tag.slice(1)) || [];

            const content = await editorRef.current?.getMarkdown();

            if(!title || !content)
                throw new Error('Missing input(s)');

            const response = await fetch('/api/blogs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    blog: {
                        title,
                        tags,
                        content,
                        status,
                    }
                }),
            });

            const res = await response.json();
  
            if(res.status === 'failed')
                throw new Error(res.message);

            toast({
                status: 'success',
                description: 'Blog saved successfully!'
            });

            const { createdBlog } = res.data;

            navigate(`/blogs/${createdBlog._id}`);
        }
        catch(err) {
            toast({
                status: 'error',
                description: err.message
            });
        }
    }

    return (
        <Box>
            <HStack mb='0.5em' className='no-select' justify='space-between'>
                <Text>
                    📝 New Blog
                </Text>
                <Link as={ReactRouterLink} to=".." relative="path">
                    Go back
                </Link>
            </HStack>
            <hr />

            <Card mt='0.7em'><CardBody>
                <VStack spacing='1em' align='left'>                

                    <Input
                        ref={titleRef}
                        _focus={{ border: '1px', borderColor: 'gray.200', boxShadow: 'none' }}
                        fontSize='xl'
                        fontWeight='bold'
                        type='text'
                        placeholder='Enter title'
                    />

                    <Input
                        ref={tagsRef}
                        _focus={{ border: '1px', borderColor: 'gray.200', boxShadow: 'none' }}
                        type='text'
                        placeholder='Enter tags'
                    />

                    <Editor editorRef={editorRef} />

                    <HStack justify='right'>

                        <Preview editorRef={editorRef}/>

                        <Button
                            colorScheme='teal'
                            onClick={() => saveBlog('draft')}
                        >
                            Save as draft 🗄️
                        </Button>

                        <Button
                            colorScheme='orange'
                            onClick={() => saveBlog('published')}
                        >
                            Publish 🚀
                        </Button>
                    </HStack>
                </VStack>
            </CardBody></Card>
        </Box>
    );
}